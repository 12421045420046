import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = ({ location }) => {
  const description =
    "申し訳ありませんが、司法書士法人みつわ合同事務所のサイト内では、お探しのページは見つかりませんでした。"
  return (
    <Layout>
      <SEO
        description={description}
        title="404: Not found"
        pagepath={location.pathname}
      />
      <div style={{ padding: "20vh 0", textAlign: "center" }}>
        <h1 style={{ fontSize: "1.03em", marginBottom: "5%" }}>
          お探しのページは見つかりませんでした。
        </h1>
        <Link to={"/"}>
          <button className="link__btn">トップページへ戻る</button>
        </Link>
      </div>
    </Layout>
  )
}

export default NotFoundPage
